<template>
  <b-row
    class="vh-100 px-0 bg-position"
    :style="{
      position: 'relative',
      overflow: 'hidden',
      backgroundImage: `url(${require('@/assets/images/background.jpg')})`,
      backgroundPosition: 'center top',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%'
    }"
  >
    <div style="position: absolute; width: 100vw; transform: rotate(180deg); bottom: 0;">
      <svg viewBox="0 50 500 200">
        <path
          d="M 0 55 C 210 150 320 0 500 100 L 500 0 L 0 0"
          fill="yellow"
          opacity="0.6"
        />
        <path
          d="M 0 55 C 200 150 320 -25 500 50 L 500 0 L 0 0"
          fill="orange"
          opacity="0.8"
        />
        <path
          d="M 0 55 C 200 150 320 -50 500 50 L 500 0 L 0 0"
          fill="#0E7452"
          opacity="1"
        />
      </svg>
    </div>

    <b-container
      class="h-100 d-flex justify-content-center align-items-center"
    >
      <b-card
        class="rounded-lg"
      >
        <template #header>
          <h5 class="text-center mb-0 font-weight-bolder">
            Inventory Transfer Management System
          </h5>
        </template>
        <b-card-text class="d-flex justify-content-center">

          <ValidationObserver
            ref="signinForm"
            v-slot="{ handleSubmit }"
          >
            <form
              role="form"
              @submit.prevent="handleSubmit(onSignIn)"
            >
              <div
                class="rounded py-3 px-2"
                style="max-width: 350px; z-index: 1;"
              >
                <b-row>
                  <b-col
                    cols="12"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          vid="username"
                          name="username"
                          rules="required"
                        >
                          <b-form-group
                            label="Username"
                            label-for="username"
                            label-class="font-weight-bold"
                            class="mb-2"
                          >
                            <b-input
                              id="username"
                              v-model="credentials.username"
                              type="text"
                              placeholder="enter username here"
                              autocomplete="off"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy"
                            />
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          vid="password"
                          name="password"
                          rules="required"
                        >
                          <b-form-group
                            label="Password"
                            label-for="password"
                            label-class="font-weight-bold"
                            class="mb-2"
                          >
                            <b-input-group
                              :class="errors.length > 0 ? 'is-invalid' : ''"
                            >
                              <b-input
                                id="password"
                                v-model="credentials.password"
                                placeholder="enter password here"
                                autocomplete="off"
                                :type="passwordType"
                                :state="errors.length > 0 ? false : null"
                                :disabled="state.busy"
                              />
                              <b-input-group-append>
                                <b-button
                                  class="pl-2 pr-1 text-dark input-icon"
                                  variant="warning"
                                  :disabled="state.busy"
                                  @click.prevent="togglePassword"
                                >
                                  <i
                                    v-if="state.visible_password"
                                    class="ri-eye-line h5"
                                  />
                                  <i
                                    v-else
                                    class="ri-eye-off-line h5"
                                  />
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="px-2 pt-3">

                  <b-overlay
                    class="col-12 px-1"
                    rounded
                    opacity="0.1"
                    spinner-variant="warning"
                    spinner-small
                    :show="state.local"
                  >
                    <b-button
                      size="sm"
                      type="submit"
                      class="w-100 font-weight-bolder"
                      variant="secondary"
                      :disabled="state.busy"
                    >
                      Sign In
                    </b-button>
                  </b-overlay>

                </b-row>
              </div>

            </form>
          </ValidationObserver>
        </b-card-text>
      </b-card>
    </b-container>
  </b-row>
</template>

<script>
import { core } from '@/config/pluginInit'
import { SSharedAuth, SSharedSSO } from '@/services'
import { Oauth2 } from 'universal-social-auth'
import url from '@/mixins/url'
import logos from '@/mixins/logos'

export default {
  name: 'SharedLogin',

  middleware: ['guest'],

  metaInfo: () => ({
    title: 'Sign In'
  }),

  mixins: [
    url,
    logos
  ],

  data: () => ({
    state: {
      busy: false,
      local: false,
      sso: false,
      visible_password: false
    },
    credentials: {
      username: null,
      password: null
    },
    Oauth2
  }),

  computed: {

    companyLogo () {
      return this.transformToImageUrl(this.getCompany())
    },

    passwordType () {
      return this.state.visible_password ? 'text' : 'password'
    }

  },

  mounted () {
    core.index()
  },

  methods: {

    togglePassword () {
      this.state.visible_password = !this.state.visible_password
    },

    async saveAndGetCurrentUser (token) {
      this.$store.dispatch('auth/saveToken', token)
      return new Promise((resolve, reject) => {
        SSharedAuth.getCurrentUser().then(
          ({ data }) => {
            this.$store.dispatch('auth/fetchUserSuccess', {
              id: data.id,
              email: data.email,
              role: data.role.code,
              role_name: data.role.name,
              username: data.role.username,
              name: data.name,
              location: data.location?.id || null,
              location_name: data.location?.location_name || null,
              location_code: data.location?.location_code || null,
              allow_in: data.location?.allow_in || null,
              allow_out: data.location?.allow_out || null,
              warehouse: data.location?.warehouse || null,
              prefix: data.location?.prefix || null
            })
            const user = this.$store.getters['auth/user']
            this.$router.replace({
              name: user ? `${user.role}.dashboard` : 'dashboard'
            }, () => {
              resolve(data)
            })
          }
        ).catch(error => {
          this.$store.dispatch('auth/fetchUserFailure')
          reject(error)
        })
      })
    },

    async onSignIn () {
      await this.$refs.signinForm.validate().then(
        allFieldsAreValid => {
          if (allFieldsAreValid) {
            this.state.busy = this.state.local = true
            SSharedAuth.signIn(this.credentials).then(
              ({ data: { token } }) => {
                this.saveAndGetCurrentUser(token).finally(() => {
                  this.state.busy = this.state.local = false
                })
              }
            ).catch(() => {
              this.state.busy = this.state.local = false
            })
          }
        }
      )
    },

    async onSignInWithAljaySSO (event) {
      event.preventDefault()
      this.state.busy = this.state.sso = true
      this.$Oauth.authenticate('oauth2', this.Oauth2).then(
        authorized => {
          SSharedSSO.post(authorized).then(
            ({ data: { token } }) => {
              this.saveAndGetCurrentUser(token).finally(() => {
                this.state.busy = this.state.local = false
              })
            }
          ).catch(() => {
            this.state.busy = this.state.sso = false
          })
        }
      )
    }
  }
}
</script>

  <style scoped>

  .text-divider {
    margin: 2em 0;
    line-height: 0;
    text-align: center;
    color: gray;
    padding: 0px 5px;
  }
  .text-divider span {
    background-color: rgb(255, 255, 255);
    padding: 0 0.5em;
  }
  .text-divider:before {
    content: " ";
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid gray;
    border-bottom: 1px solid #f7f7f7;
  }
  .text-divider span:before  {
    content: "";
    display: block;
    margin-top: -3px;
  }
  .text-divider span:after  {
    display: block;
    margin-top: -3px;
  }

  .bg-position {
    background-color: #757783;
    background-size: 100vh auto !important;
    background-position: 50% center !important;
    height: 100vh !important;
  }

  @media screen and (orientation: landscape) {
    .bg-position {
      background-position: center 70% !important;
      background-size: 100% !important;
    }
  }

  @media screen and (orientation: landscape) and (max-width: 1024px) {
  }

  @media screen and (min-width: 1024px) {
    .bg-position {
      background-position: center 70% !important;
      background-size: 100% !important;
    }
  }
  </style>
